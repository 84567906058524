<template lang="pug">
  .text-center.pt-10
    v-pagination(
      v-model="data.current_page"
      :length="data.last_page"
      :total-visible="10"
      @input="v => changeUrl(v)"
    )
</template>

<script>
export default {
  name: "Paginate",
  props: [ 'data' ],

  watch: {
    'v' : 'changeUrl'
  },
  methods: {
    changeUrl: function(value){
      let self = this
      let params = {...this.$route.query}
      console.log( params )

      params.page = value
      this.$nextTick(function(){
          self.$router.push( { query: params } )
      })
    }
  },
}
</script>